<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>
        <router-link to="/admin/appointments">Termine</router-link>
        <p>
          <span v-if="appointment.appointment_type == 'planned'">#T{{ appointment.appointment_number }}</span>
          <span v-if="appointment.appointment_type == 'spontaneous'">#A{{ appointment.appointment_number }}</span>
          <span>{{ appointment.first_name }} {{ appointment.last_name }}</span>
        </p>
      </h1>
      <div class="meta">
        <a v-if="!pdf_confirmation_loading" @click="download_confirmation" class="button button-red" style="margin-right:10px;padding: 0.5rem 1rem">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
          </svg>
          <span>Bestätigung</span>
        </a>
        <div v-else class="loading-wrap" style="display:inline-block;vertical-align:middle;padding:0;width:176px">
          <span class="loading-spinner" style="margin:auto"></span>
        </div>
        <a v-if="!pdf_loading" @click="download_pdf" class="button button-red" style="padding: 0.5rem 1rem">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
          </svg>
          <span>Bon Ausdrucken</span>
        </a>
        <div v-else class="loading-wrap" style="display:inline-block;vertical-align:middle;padding:0;width:176px">
          <span class="loading-spinner" style="margin:auto"></span>
        </div>
        <a @click="create_receipt" v-if="appointment.location && appointment.location.payment_type == 'laser_printer'" class="button button-red" style="margin-left:10px">
          <svg xmlns="http://www.w3.org/2000/svg" style="width:20px;height:auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          <span>Quittung erstellen</span>
        </a>
      </div>
    </header>

    <div class="row row-gutter-20">
      <div class="col-16">
        <div class="content-wrap">
          <h3>Termin & Kunde</h3>

          <div class="row row-gutter-20">
            <div class="col-12">
              <div class="form-wrap">
                <label for="date">Datum</label>
                <span style="display:block;width:100%;border-radius: 4px;padding: 10px 12px;font-size: 14px;background:#f9f9f9;border: 1px solid #D7E3E6;box-shadow: inset 0 0 3px 0 rgba(215, 227, 230, 0.4);">
                  {{ appointment.date_formatted }}
                </span>
              </div>
            </div>
            <div class="col-12">
              <div class="form-wrap">
                <label for="time">Uhrzeit</label>
                <span style="display:block;width:100%;border-radius: 4px;padding: 10px 12px;font-size: 14px;background:#f9f9f9;border: 1px solid #D7E3E6;box-shadow: inset 0 0 3px 0 rgba(215, 227, 230, 0.4);">
                  {{ appointment.time_formatted }}
                </span>
              </div>
            </div>
          </div>

          <p v-if="change_appointment_date == false" style="text-align:right;margin-top:0"><span @click="change_appointment_date = true" class="button button-red button-small" style="padding: 0.25rem 1rem;">Datum & Uhrzeit ändern</span></p>
          <div v-if="change_appointment_date" style="background:#f9f9f9;padding:24px;margin-bottom:40px;border-radius:5px;position:relative">

            <p v-if="change_appointment_date == true" style="text-align:right;margin-top:0;position:absolute;right:0;top:0;"><span @click="change_appointment_date = false" class="button button-gray button-small" style="padding: 0.25rem 1rem;">Schließen</span></p>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="form-wrap" style="margin:0">
                  <label for="date">Bitte neues Datum auswählen</label>
                  <v-date-picker v-model="changed_appointment.date" :min-date='cal_min_date' :max-date='cal_max_date' :disabled-dates="cal_disabled_dates" :first-day-of-week="2" locale="de" color="red" mode="date">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 form-input"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="col-12">
                <div v-if="changed_appointment.date" class="form-wrap">
                  <label for="time">Neue Uhrzeit</label>
                  <select v-model="changed_appointment.time" class="form-input">
                    <option v-for="slot in slots.slots" v-bind:key="slot" v-bind:value="slot">{{ slot }}</option>
                  </select>
                </div>
              </div>
            </div>

            <p v-if="changed_appointment.date && changed_appointment.time" style="margin-bottom:0">
              <a @click="update_date_time" class="button button-red">Datum & Uhrzeit speichern</a>
            </p>

          </div>

          <div class="row row-gutter-20">
            <div class="col-12">
              <div class="form-wrap">
                <label for="first_name">Vorname</label>
                <input v-model="appointment.first_name" type="text" class="form-input" id="first_name">
              </div>
            </div>
            <div class="col-12">
              <div class="form-wrap">
                <label for="last_name">Nachname</label>
                <input v-model="appointment.last_name" type="text" class="form-input" id="last_name">
              </div>
            </div>
          </div>

          <div class="row row-gutter-20">
            <div class="col-12">
              <div class="form-wrap">
                <label for="email">E-Mail Adresse</label>
                <input v-model="appointment.email" type="text" class="form-input" id="email">
              </div>
            </div>
            <div class="col-12">
              <div class="form-wrap">
                <label for="phone">Telefon</label>
                <input v-model="appointment.phone" type="text" class="form-input" id="phone">
              </div>
            </div>
          </div>

          <div class="form-wrap">
            <label for="description">Notiz zu diesem Termin</label>
            <textarea v-model="appointment.description" id="description" name="description" rows="4" class="form-input"></textarea>
          </div>

          <p style="margin-bottom:0">
            <a @click="update_name" class="button button-red">Stammdaten ändern</a>
          </p>
        </div>

        <div class="content-wrap" style="position:relative">
          <h3>Leistungen</h3>

          <div v-if="loading_update_services" class="loading-wrap">
            <span class="loading-spinner"></span>
          </div>
          <div v-else>
            <div v-if="appointment_services && appointment_services.length == 0" class="alert alert-info">
              <p>Aktuell sind noch keine Leistungen hinterlegt.</p>
            </div>
            <div v-else>
              <div v-for="(service, i) in appointment_services" v-bind:key="i" class="app-service-wrap">

                <header class="service-header">
                  <h4>Leistung #{{ i+1 }}</h4>
                  <a @click="delete_service(service)" class="button button-light-gray">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash-can" class="svg-inline--fa fa-trash-can" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path fill="currentColor" d="M432 80h-82.38l-34-56.75C306.1 8.827 291.4 0 274.6 0H173.4C156.6 0 141 8.827 132.4 23.25L98.38 80H16C7.125 80 0 87.13 0 96v16C0 120.9 7.125 128 16 128H32v320c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V128h16C440.9 128 448 120.9 448 112V96C448 87.13 440.9 80 432 80zM171.9 50.88C172.9 49.13 174.9 48 177 48h94c2.125 0 4.125 1.125 5.125 2.875L293.6 80H154.4L171.9 50.88zM352 464H96c-8.837 0-16-7.163-16-16V128h288v320C368 456.8 360.8 464 352 464zM224 416c8.844 0 16-7.156 16-16V192c0-8.844-7.156-16-16-16S208 183.2 208 192v208C208 408.8 215.2 416 224 416zM144 416C152.8 416 160 408.8 160 400V192c0-8.844-7.156-16-16-16S128 183.2 128 192v208C128 408.8 135.2 416 144 416zM304 416c8.844 0 16-7.156 16-16V192c0-8.844-7.156-16-16-16S288 183.2 288 192v208C288 408.8 295.2 416 304 416z"></path>
                    </svg>
                    <span>Löschen</span>
                  </a>
                </header>

                <div class="service-body">

                  <div class="row row-gutter-20">
                    <div class="col-8">
                      <div class="form-wrap select-wrap">
                        <label for="location_id">Standort</label>
                        <select v-model="service.location_id" @change="location_changed(service, service.location_id)" class="form-input">
                          <option v-for="location in computed_locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-wrap select-wrap">
                        <label for="category_id">Kategorie</label>
                        <select v-model="service.category_id" @change="category_changed(service, service.category_id)" class="form-input">
                          <option v-for="category in service.categories" v-bind:key="category.id" v-bind:value="category.id">{{ category.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-wrap select-wrap">
                        <label for="category_id">Leistung</label>
                        <select v-model="service.service_id" @change="service_changed(service)" class="form-input">
                          <option v-for="service in service.services" v-bind:key="service.id" v-bind:value="service.id">{{ service.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row row-gutter-20">
                    <div class="col-8">
                      <div v-if="service.fees && service.fees.length > 0">
                        <div class="form-wrap select-wrap">
                          <label for="category_id">Gebühr</label>
                          <select v-model="service.fee_id" @change="fee_changed(service)" class="form-input">
                            <option v-for="fee in service.fees" v-bind:key="fee.id" v-bind:value="fee.id">{{ fee.name }} ({{ fee.price_formatted }})</option>
                          </select>
                        </div>
                      </div>
                      <div v-else>
                        <span style="color:white">_</span>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-wrap">
                        <label for="service_amount">Anzahl</label>
                        <input v-model="service.service_amount" @change="amount_changed(service)" type="text" class="form-input" id="service_amount">
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="form-wrap">
                        <label for="service_price">Kosten</label>
                        <currency-input v-model="service.service_price" :options="{ currency: 'EUR', locale: 'de-DE', hideCurrencySymbolOnFocus: false }" class="form-input" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <p v-if="appointment_services.length > 0" style="margin-bottom:0">
                  <a @click="update_services" class="button button-red">Daten speichern ›</a>
                </p>
                <p v-else>
                  <span style="color:#fff">_</span>
                </p>
              </div>
              <div class="col-12" style="text-align:right">
                <p style="margin-bottom:0">
                  <a @click="add_service" class="button button-light-gray">Leistung hinzufügen</a>
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-8">
        <div v-if="appointment.location && appointment.location.payment_type == 'bon_printer'" class="content-wrap">
          <h3>Interne Verwaltung</h3>
          <div v-if="appointment.status == 'created'">
            <p style="margin-bottom:10px">Der Bürger ist noch nicht eingecheckt.</p>
            <p style="margin-top:0;margin-bottom:0;padding-top:14px">
              <a @click="check_in_appointment" class="button button-gray">Jetzt einchecken ›</a>
            </p>
          </div>
          <div v-if="appointment.status == 'checked_in'">
            <p style="font-size:14px;color:#1A1D1C;margin-bottom:0">
              <svg style="color: #444;display:inline-block;vertical-align:middle;width:14px;margin-right:5px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-info" class="svg-inline--fa fa-circle-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"></path>
              </svg>
              <span style="color: #444;display:inline-block;vertical-align:middle;">Wartet auf Aufruf durch Mitarbeiter.</span>
            </p>
          </div>
          <div v-if="appointment.status == 'called'">
            <div class="form-wrap" style="margin-bottom:10px">
              <label for="duration">Voraussichtliche Dauer</label>
              <span style="display:block;width:100%;border-radius: 4px;padding: 10px 12px;font-size: 14px;background:#f9f9f9;border: 1px solid #D7E3E6;box-shadow: inset 0 0 3px 0 rgba(215, 227, 230, 0.4);">
                {{ appointment.duration }} Minuten
              </span>
            </div>
            <div class="row row-gutter-20">
              <div class="col-8">
                <p style="margin-bottom:0;padding-top:14px">
                  <a @click="cancel_appointment" style="color:#9C2128;font-weight:600;">Stornieren ›</a>
                </p>
              </div>
              <div class="col-16">
                <p style="margin-bottom:0;text-align:right">
                  <a @click="start_appointment" class="button button-green">Termin starten ›</a>
                </p>
              </div>
            </div>
          </div>
          <div v-if="appointment.status == 'running'">
            <p style="font-size:14px;color:#1A1D1C">
              <svg style="color: #444;display:inline-block;vertical-align:middle;width:14px;margin-right:5px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-info" class="svg-inline--fa fa-circle-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"></path>
              </svg>
              <span style="color: #444;display:inline-block;vertical-align:middle;">Termin gestartet am {{ appointment.started_at_formatted }}</span>
            </p>
            <div class="row row-gutter-10">
              <div class="col-12">
                <p style="margin-bottom:0;padding-top:14px">
                  <a @click="cancel_appointment" class="button button-red button-100">Abbrechen</a>
                </p>
              </div>
              <div class="col-12">
                <p style="margin-bottom:0;padding-top:14px">
                  <a @click="complete_appointment" class="button button-green button-100">Abschließen</a>
                </p>
              </div>
            </div>
          </div>
          <div v-if="appointment.status == 'completed'" style="position:relative">
            <a @click="edit_appointment_timestamps = true" v-if="edit_appointment_timestamps == false" style="position:absolute;right:0;bottom:0;background: rgb(156, 33, 40);display: block;padding:5px 10px;border-radius:5px">
              <svg style="width:10px;height:10px;color:#fff;" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
              </svg>
            </a>
            <a @click="edit_appointment_timestamps = false" v-if="edit_appointment_timestamps == true" style="position:absolute;right:0;bottom:0;background: rgb(156, 33, 40);display: block;padding:5px 10px;border-radius:5px">
              <svg style="width:10px;height:10px;color:#fff;" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
            <p style="font-size:14px;color:#1A1D1C;margin-bottom:0">
              <svg style="color: #444;display:inline-block;vertical-align:middle;width:14px;margin-right:5px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-info" class="svg-inline--fa fa-circle-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"></path>
              </svg>
              <span style="color: #444;display:inline-block;vertical-align:middle;">
                Termin erfolgreich abgeschlossen.
              </span>
            </p>
            <div v-if="edit_appointment_timestamps" style="padding-top:20px">
              <div class="form-wrap">
                <label for="started_at">Gestartet:</label>
                <input v-model="appointment.started_at_rfc3339" type="datetime-local" id="started_at" name="started_at" class="form-input">
              </div>
              <div class="form-wrap">
                <label for="completed_at">Abgeschlossen:</label>
                <input v-model="appointment.completed_at_rfc3339" type="datetime-local" id="completed_at" name="completed_at" class="form-input">
              </div>
              <p style="margin-bottom:0">
                <a @click="update_appointment_timestamps" class="button button-red">Speichern</a>
              </p>
            </div>
            <div v-else>
              <p style="font-size:14px;color:#1A1D1C;margin-bottom:0">
                Gestartet: {{ appointment.started_at_formatted }}<br>
                Abgeschlossen: {{ appointment.completed_at_formatted }}
              </p>
            </div>
          </div>
          <div v-if="appointment.status == 'canceled'">
            <p style="font-size:14px;color:#1A1D1C;margin-bottom:0">
              <svg style="color: #444;display:inline-block;vertical-align:middle;width:14px;margin-right:5px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-info" class="svg-inline--fa fa-circle-info" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"></path>
              </svg>
              <span style="color: #444;display:inline-block;vertical-align:middle;">Termin storniert</span>
            </p>
          </div>
          <div v-if="appointment.status == 'paused'">
            <p style="margin-bottom:10px">Der Termin ist pausiert.</p>
            <p style="margin-bottom:0;padding-top:14px">
              <a @click="call_appointment_from_paused" class="button button-red button-100">Neu aufrufen ›</a>
            </p>
            <p style="margin-top:10px;margin-bottom:0;">
              <a @click="start_appointment" class="button button-green button-100">Starten ›</a>
            </p>
          </div>
        </div>
        <div class="content-wrap">
          <h3>Status anpassen</h3>
          <div class="form-wrap select-wrap">
            <label for="location_id">Status</label>
            <select v-model="selected_status" class="form-input">
              <option key="created" value="created">Reserviert</option>
              <option key="checked_in" value="checked_in">Eingecheckt</option>
              <option key="called" value="called">Aufgerufen</option>
              <option key="running" value="running">Läuft</option>
              <option key="canceled" value="canceled">Storniert</option>
              <option key="completed" value="completed">Fertig</option>
              <option key="paused" value="paused">Pausiert</option>
              <option key="not_appeared" value="not_appeared">Nicht erschienen</option>
            </select>
          </div>
          <p style="margin-bottom:0">
            <a @click="update_status" class="button button-red">Status ändern</a>
          </p>
        </div>
        <div class="content-wrap" style="position:relative">
          <h3>Zahlungsstatus</h3>
          <a @click="get_appointment" style="position:absolute;right:20px;top:25px;background:#9C2128;display:block;padding:5px 10px;border-radius:5px">
            <svg style="width:10px;height:10px;color:#fff" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-rotate-right" class="svg-inline--fa fa-arrow-rotate-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" d="M496 48V192c0 17.69-14.31 32-32 32H320c-17.69 0-32-14.31-32-32s14.31-32 32-32h63.39c-29.97-39.7-77.25-63.78-127.6-63.78C167.7 96.22 96 167.9 96 256s71.69 159.8 159.8 159.8c34.88 0 68.03-11.03 95.88-31.94c14.22-10.53 34.22-7.75 44.81 6.375c10.59 14.16 7.75 34.22-6.375 44.81c-39.03 29.28-85.36 44.86-134.2 44.86C132.5 479.9 32 379.4 32 256s100.5-223.9 223.9-223.9c69.15 0 134 32.47 176.1 86.12V48c0-17.69 14.31-32 32-32S496 30.31 496 48z"></path>
            </svg>
          </a>
          <div class="row row-gutter-20">
            <div class="col-12">
              <p style="margin: 5px 0"><strong>Erwarteter Betrag</strong></p>
              <p style="margin: 5px 0"><strong>Bezahlter Betrag</strong></p>
              <p style="margin: 5px 0"><strong>Aktueller Status</strong></p>
            </div>
            <div class="col-12">
              <p style="margin: 5px 0">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'eur', }).format(appointment.service_price) }}</p>
              <p v-if="appointment.payment_status == 'paid'" style="margin: 5px 0">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'eur', }).format(appointment.service_price) }}</p>
              <p v-else style="margin: 5px 0">{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'eur', }).format(0.0) }}</p>
              <p style="margin: 5px 0">
                <span v-if="appointment.payment_status == 'pending'">Unbezahlt</span>
                <span v-if="appointment.payment_status == 'paid'">Bezahlt</span>
                <span v-if="appointment.payment_status == 'failed'">Fehlgeschlagen</span>
              </p>
            </div>
          </div>
          <div v-if="appointment.service_price > 0.0">
            <hr style="margin: 25px 0 20px 0">
            <h4 style="font-weight:600;color:#9C2128;margin:20px 0 15px 0">Zahlungsstatus manuell anpassen</h4>
            <div class="form-wrap select-wrap">
              <select v-model="appointment.payment_status" class="form-input">
                <option key="pending" value="pending">Unbezahlt</option>
                <option key="pending" value="paid">Bezahlt</option>
                <option key="pending" value="failed">Fehlgeschlagen</option>
              </select>
            </div>
            <div class="form-wrap select-wrap">
              <select v-model="appointment.payment_method" class="form-input">
                <option key="cash" value="cash">Bar</option>
                <option key="card" value="card">Karte</option>
                <option key="cheque" value="cheque">Scheck</option>
              </select>
            </div>
            <p style="margin-bottom:0">
              <a @click="update_payment_details" class="button button-red">Zahlungsstatus ändern</a>
            </p>
          </div>
        </div>
        <div class="content-wrap">
          <h3>Interne Notiz</h3>
          <div class="form-wrap">
            <label for="description">Interne Notiz</label>
            <textarea v-model="appointment.internal_note" id="description" name="description" rows="4" class="form-input"></textarea>
          </div>
          <p style="margin-bottom:0">
            <a @click="update_internal_note" class="button button-red">Notiz speichern ›</a>
          </p>
        </div>
        <div v-if="appointment.location && appointment.location.feature_waiting_line && appointment.location.payment_type == 'bon_printer'" class="content-wrap">
          <h3>Schalter & Mitarbeiter</h3>
          <div class="form-wrap select-wrap">
            <label for="category_id">Schalter</label>
            <select v-model="appointment.counter_id" class="form-input">
              <option v-for="counter in counters" v-bind:key="counter.id" v-bind:value="counter.id">{{ counter.name }}</option>
              <option key="" value="">Kein Schalter hinterlegt</option>
            </select>
          </div>
          <div class="form-wrap select-wrap">
            <label for="category_id">Benutzer</label>
            <select v-model="appointment.user_id" class="form-input">
              <option v-for="user in users" v-bind:key="user.id" v-bind:value="user.id">{{ user.last_name }}, {{ user.first_name }}</option>
              <option key="" value="">Kein Benutzer hinterlegt</option>
            </select>
          </div>
          <p style="margin-bottom:0">
            <a @click="update_counter" class="button button-red">Status ändern</a>
          </p>
        </div>
        <div style="padding: 2px 0 50px 0;text-align:center">
          <p style="font-size:14px;margin-bottom:0">Termin vereinbart am {{ appointment.created_at_formatted }}.</p>
          <p v-if="appointment.created_by_name" style="font-size:14px;margin-top:5px">Termin wurde angelegt von {{ appointment.created_by_name }}.</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CurrencyInput from '@/components/CurrencyInput'

export default {
  name: 'admin_appointments',
  components: { CurrencyInput },
  data () {
    return {
      loading_update_services: true,
      pdf_loading: false,
      pdf_loading_old: false,
      pdf_confirmation_loading: false,
      locations: [],
      user_id: null,
      user: {},
      user_location: null,
      appointment: {},
      categories: [],
      services: [],
      fees: [],
      counters: [],
      users: [],
      fee: null,
      selected_status: "",
      appointment_services: [],
      changed_appointment: {
        date: null,
        time: null
      },
      slots: [],
      cal_disabled_dates: [],
      cal_min_date: null,
      cal_max_date: null,
      change_appointment_date: false,
      edit_appointment_timestamps: false
    }
  },
  computed: {
    computed_locations: function () {
      var locations = [];
      this.locations.forEach(location => {
        if (this.user_location) {
          if (this.user_location == location.id) {
            locations.push(location);
          }
        } else {
          locations.push(location);
        }
      })
      return locations;
    },
  },
  methods: {
    async get_data() {
      this.appointment_services = [];
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.locations = response.data.locations;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/user', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user_id = response.data.user.id;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/users/'+this.user_id, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user = response.data.user;
        if (this.user.locations && this.user.locations.length > 0) {
          this.user_location = this.user.locations[0].id;
        }
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.appointment = response.data.appointment;
        this.selected_status = this.appointment.status;

        this.push_services();
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/counters', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.counters = response.data.counters;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/users', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.users = response.data.users;
      })
      /*await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.categories = response.data.categories;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.services = response.data.services;
      })*/
    },
    async push_services() {
      for (let i = 0; i < this.appointment.services.length; i += 1) {

        var locations = this.locations;
        var categories = await this.get_categories(this.appointment.services[i].location_id)
        var services = await this.get_services(this.appointment.services[i].category_id)
        var fees = await this.get_fees(this.appointment.services[i].service_id)

        this.appointment_services.push({
          locations: locations,
          categories: categories,
          services: services,
          fees: fees,
          location_id: this.appointment.services[i].location_id,
          category_id: this.appointment.services[i].category_id,
          service_id: this.appointment.services[i].service_id,
          fee_id: this.appointment.services[i].fee_id,
          service_amount: this.appointment.services[i].service_amount,
          service_price: this.appointment.services[i].service_price
        })

      }
      this.loading_update_services = false;
    },
    async get_appointment() {
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.appointment = response.data.appointment;
        this.$notify({
          title: "Erfolgreich neu geladen.",
          type: "success"
        });
      })
    },
    update_internal_note() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        internal_note: this.appointment.internal_note
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      })
    },
    update_appointment() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        first_name: this.appointment.first_name,
        last_name: this.appointment.last_name,
        email: this.appointment.email,
        phone: this.appointment.phone,
        description: this.appointment.description,
        location_id: this.appointment.location_id,
        category_id: this.appointment.category_id,
        service_id: this.appointment.service_id,
        fee_id: this.appointment.fee_id,
        service_amount: this.appointment.service_amount,
        service_price: this.appointment.service_price,
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      })
    },
    update_appointment_timestamps() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        started_at: this.appointment.started_at_rfc3339,
        completed_at: this.appointment.completed_at_rfc3339
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.edit_appointment_timestamps = false;
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      })
    },
    cancel_appointment() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        status: 'canceled'
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich storniert.",
          type: "success"
        });
        if (this.$route.query.user_id) {
          this.$router.push('/admin/dashboard');
        }
      })
    },
    start_appointment() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        status: 'running',
        started_at: new Date()
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich gestartet.",
          type: "success"
        });
      })
    },
    call_appointment_from_paused() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        status: 'called',
        started_at: new Date()
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich gestartet.",
          type: "success"
        });
      })
    },
    check_in_appointment() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        status: 'checked_in'
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich eingecheckt.",
          type: "success"
        });
      })
    },
    complete_appointment() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        status: 'completed',
        completed_at: new Date()
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich abgeschlossen.",
          type: "success"
        });
        if (this.$route.query.user_id) {
          this.$router.push('/admin/dashboard');
        }
      })
    },
    download_pdf() {
      this.pdf_loading = true;
      return(this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid+'/pdf', { responseType: 'blob', headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          var fileURL = URL.createObjectURL(blob);
          var page = window.open(fileURL, this.appointment.id, 'width=1200,height=800');
          setTimeout(function(){
            page.focus();
            page.print();
          }, 100);
          //myWindow.print();
          //myWindow.focus();
          //const link = document.createElement('a')
          //link.href = URL.createObjectURL(blob)
          //var pdf_name = "Termin-"+this.appointment.appointment_number+".pdf"
          //link.download = pdf_name
          //link.onclick = "window.open(this.href).print(); return false"
          //link.target = '_blank';
          //console.log(link);
          //link.click()
          //URL.revokeObjectURL(link.href);
          this.pdf_loading = false;
      }))
    },
    download_pdf_old() {
      this.pdf_loading_old = true;
      return(this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid+'/pdf', { responseType: 'blob', headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          var pdf_name = "Termin-"+this.appointment.appointment_number+".pdf"
          link.download = pdf_name
          link.click()
          URL.revokeObjectURL(link.href);
          this.pdf_loading_old = false;
      }))
    },
    download_confirmation() {
      this.pdf_confirmation_loading = true;
      return(this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid+'/confirmation_pdf', { responseType: 'blob', headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          var fileURL = URL.createObjectURL(blob);
          var page = window.open(fileURL, this.appointment.id, 'width=1200,height=800');
          setTimeout(function(){
            page.focus();
            page.print();
          }, 100);
          this.pdf_confirmation_loading = false;
      }))
    },
    update_status() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        status: this.selected_status,
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      })
    },
    update_counter() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        counter_id: this.appointment.counter_id,
        user_id: this.appointment.user_id
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      })
    },
    create_receipt() {
      if(confirm("Möchten Sie aus diesem Termin eine Quittung erstellen?")) {
        this.$http.post(process.env.VUE_APP_BASE_API+'/admin/receipts', {
          location_id: this.appointment.location_id,
          recipient: this.appointment.first_name+" "+this.appointment.last_name
        }, { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success"
          });
          this.$router.push('/admin/receipts/'+response.data.receipt.id);
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
      }
    },
    add_service() {
      var locations = [];
      locations = this.locations;
      this.appointment_services.push({
        locations: locations,
        categories: [],
        services: [],
        fees: [],
        location_id: this.appointment.location_id,
        category_id: "",
        service_id: "",
        fee_id: "",
        service_amount: 1,
        service_price: 0.0
      })
      this.location_changed(this.appointment_services[parseInt(this.appointment_services.length-1)], this.appointment.location_id)
    },
    delete_service(service) {
      this.appointment_services.splice(this.appointment_services.indexOf(service), 1);
    },
    async location_changed(service, location_id) {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { params: { location_id: location_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        service.categories = response.data.categories;
      })
    },
    async get_categories(location_id) {
      var categories = []
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { params: { location_id: location_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        for (let i = 0; i < response.data.categories.length; i += 1) {
          categories.push(response.data.categories[i])
        }
      })
      return categories;
    },
    async category_changed(service, service_id) {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', { params: { category_id: service_id, sort_by: "name" }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        service.services = response.data.services;
      })
    },
    async get_services(category_id) {
      var services = []
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', { params: { category_id: category_id, sort_by: "name" }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        for (let i = 0; i < response.data.services.length; i += 1) {
          services.push(response.data.services[i])
        }
      })
      return services;
    },
    async service_changed(service) {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services/'+service.service_id+'/fees', {
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        service.fees = response.data.fees;
      })
    },
    async get_fees(service_id) {
      var fees = []
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services/'+service_id+'/fees', {
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        for (let i = 0; i < response.data.fees.length; i += 1) {
          fees.push(response.data.fees[i])
        }
      })
      return fees;
    },
    async fee_changed(service) {
      var selected_fee = null;
      service.fees.forEach(fee => {
        if (fee.id == service.fee_id) {
          selected_fee = fee;
        }
      })
      if (selected_fee) {
        service.fee_id = selected_fee.id;
        service.service_price = service.service_amount*selected_fee.price;
      }
    },
    async amount_changed(service) {
      var selected_fee = null;
      service.fees.forEach(fee => {
        if (fee.id == service.fee_id) {
          selected_fee = fee;
        }
      })
      if (selected_fee) {
        service.service_price = service.service_amount*selected_fee.price;
      }
    },
    async update_services() {
      this.loading_update_services = true;
      await this.$http.delete(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.appointment.id+'/services', {
        headers: { Authorization: this.$store.getters.get_token }
      })
      var created_services = 0;
      for (let i = 0; i < this.appointment_services.length; i += 1) {
        await this.$http.post(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.appointment.id+'/services', {
          appointment_id: this.appointment.id,
          service_id: this.appointment_services[i].service_id,
          fee_id: this.appointment_services[i].fee_id,
          service_amount: this.appointment_services[i].service_amount,
          service_price: this.appointment_services[i].service_price
        }, {
          headers: { Authorization: this.$store.getters.get_token }
        })
        .then(() => {
          created_services = created_services+1;
        })
      }
      if (created_services == this.appointment_services.length) {
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      }
      this.get_data();
    },
    update_name() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        first_name: this.appointment.first_name,
        last_name: this.appointment.last_name,
        email: this.appointment.email,
        phone: this.appointment.phone,
        description: this.appointment.description,
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      })
    },
    update_date_time() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        date: this.changed_appointment.date,
        time: this.changed_appointment.time,
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
        this.change_appointment_date = false;
        this.changed_appointment = {
          date: null,
          time: null
        }
      })
    },
    update_payment_details() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.$route.params.uuid, {
        payment_status: this.appointment.payment_status,
        payment_method: this.appointment.payment_method
      }, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      })
    },
    get_slots(date) {
      this.slots = [];

      var services = [];
      this.appointment_services.forEach(service => {
        services.push({
          id: service.service_id,
          amount: service.service_amount
        })
      })

      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/slots', {
        params: {
          date: date,
          services: services,
          service_id: services[0].id
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.slots = response.data;
      });
    },
  },
  mounted () {
    this.get_data();
  },
  watch: {
    'appointment.location_id': function() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { params: { location_id: this.appointment.location_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.categories = response.data.categories;
      })
    },
    'appointment.category_id': function() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', { params: { category_id: this.appointment.category_id, sort_by: "name" }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.services = response.data.services;
      })
    },
    'appointment.service_id': function() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services/'+this.appointment.service_id+'/fees', {
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.fees = response.data.fees;
        /*this.services.forEach(service => {
          if (service.id == this.appointment.service_id) {
            this.appointment.service_price = service.price;
          }
        })*/
        //this.appointment.service_price = 0.0;
      })
    },
    'appointment.fee_id': function() {
      var selected_fee = null;
      this.fees.forEach(fee => {
        if (fee.id == this.appointment.fee_id) {
          selected_fee = fee;
        }
      })
      if (selected_fee) {
        this.appointment.fee_id = selected_fee.id;
        this.appointment.service_price = this.appointment.service_amount*selected_fee.price;
      }
    },
    'appointment.service_amount': function() {
      var selected_fee = null;
      this.fees.forEach(fee => {
        if (fee.id == this.appointment.fee_id) {
          selected_fee = fee;
        }
      })
      if (selected_fee) {
        this.appointment.service_price = this.appointment.service_amount*selected_fee.price;
      }
    },
    'change_appointment_date': function() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/service_dates', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        response.data.forEach(date => {
          if (date.weekdays) {
            this.cal_disabled_dates.push(date)
          } else {
            this.cal_disabled_dates.push({
              start: new Date(date.start),
              end: new Date(date.end)
            })
          }
        });
      });
      this.cal_min_date = new Date();
      var d = new Date();
      d.setMonth(d.getMonth() + 3);
      this.cal_max_date = d;
    },
    'changed_appointment.date': function() {
      if (this.changed_appointment.date) {
        this.changed_appointment.time = null;
        this.get_slots(this.changed_appointment.date);
      }
    },
  }
}
</script>
